@import 'components/mixins.scss';

.topbar {
  // padding: 0 rem(30);
  min-height: 64px;
  height: 64px;
  color: $text;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  @media (max-width: $sm-max-width) {
    padding: 0 rem(16);
  }

  @media (max-width: 768px) {
    padding-right: 36px;
  }
}

.v1Link {
  display: inline-block;
  transform: translateY(2px);
  color: #b78b49 !important;
  white-space: nowrap;
}

.v1LinkWrapper {
  margin-right: 2px;
}

.logoLink {
  position: absolute;
  left: 12px;
  // &::after {
  //   content: 'BETA';
  //   display: block;
  //   position: absolute;
  //   top: 2px;
  //   right: -32px;
  //   background: #b78b49;
  //   line-height: 1;
  //   padding: 4px;
  //   color: #fff;
  //   font-size: 12px;
  //   border-radius: 8px;
  // }
}

.logo {
  width: 140px;
}

@media screen and (min-width: 768px) {
  .logoLink {
    display: none;
  }
}

@media (max-width: 768px) {
  .v1LinkWrapper {
    display: none;
  }
}

// dark theme
[data-kit-theme='dark'] {
  .topbar {
    background: $dark-banner-bg;
    color: $dark-banner-color;
  }
  .v1Link {
    color: #fff !important;
    &:hover {
      color: #b78b49 !important;
    }
  }
}
