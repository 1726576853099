@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
// @import '~c3/c3.min.css';
// @import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/bootstrap/style';
@import 'components/kit/vendors/perfect-scrollbar/style';
@import 'components/kit/vendors/nprogress/style';
// @import 'components/kit/vendors/chartist/style';
// @import 'components/kit/vendors/chartist-tooltip-plugin/style';
// @import 'components/kit/vendors/jvectormap/style';
// @import 'components/kit/vendors/react-sortable-tree/style';
// @import 'components/kit/vendors/react-draft-wysiwyg/style';

@import 'components/kit/core/style'; // Clean UI KIT styles
@import 'components/cleanui/styles/style'; // Clean UI styles

// @font-face {
//   font-family: 'avenir';
//   font-weight: 400;
//   src: url('./assets/avenir/Avenir-Book-01.ttf');
// }

// @font-face {
//   font-family: 'avenir';
//   font-weight: 600;
//   src: url('./assets/avenir/Avenir-Medium-09.ttf');
// }

// @font-face {
//   font-family: 'avenir';
//   font-weight: 800;
//   src: url('./assets/avenir/Avenir-Heavy-05.ttf');
// }

@font-face {
  font-family: 'museo';
  font-weight: 100;
  src: url('./assets/museo/Museo100-Regular.otf');
}

@font-face {
  font-family: 'museo';
  font-weight: 300;
  src: url('./assets/museo/Museo300-Regular.otf');
}

@font-face {
  font-family: 'museo';
  font-weight: 500;
  src: url('./assets/museo/Museo500-Regular.otf');
}

@font-face {
  font-family: 'museo';
  font-weight: 700;
  src: url('./assets/museo/Museo700-Regular.otf');
}

@font-face {
  font-family: 'museo';
  font-weight: 900;
  src: url('./assets/museo/Museo900-Regular.otf');
}

body {
  font-family: 'museo', sans-serif;
  font-weight: 500;
}

img {
  max-width: 100%;
}

.hidden {
  display: none !important;
}

.center-block {
  margin: 0 auto;
  display: block !important;
}

.input-more {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 44px;
  span {
    margin-right: 8px;
  }
}

.topbar-wrapper {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

svg {
  touch-action: auto;
}

.container {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  .ant-alert {
    margin: 24px 0 32px;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1100px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1300px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1500px;
  }
}

.card-title {
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #0f101e !important;
}

.cursor-pointer{
  cursor: pointer;
}

.ant-table {
  background: transparent !important;
  text-align: right;
  .ant-table-thead {
    border: none;
    tr > th {
      color: $dark-gray-9;
      text-align: right;
      border: none;
      background: transparent !important;
    }
  }

  .ant-table-tbody {
    border: none;
    tr:nth-child(2) td:first-child {
      border-top-left-radius: 8px;
    }
    tr:nth-child(2) td:last-child {
      border-top-right-radius: 8px;
    }
    tr:last-child td:first-child {
      border-bottom-left-radius: 8px;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 8px;
    }
    tr {
      background: $light-card-bg;
      border-radius: 8px;
      margin-bottom: 24px;
      border: none;
      overflow: hidden;
      &:hover {
        background: $light-card-bg;
        td {
          background: $light-card-bg;
        }
      }
      td {
        color: $light-color;
        text-align: right;
        border: none;
        position: unset;
      }
    }
  }
}

.header-menu {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  .header-menu-content {
    background: #fff;
    height: 52px;
    line-height: 52px !important;
    border: none !important;
  }
  li:first-child {
    a {
      padding: 0 24px 0 0 !important;
    }
  }
  a {
    padding: 0 24px !important;
    color: #b8b6c4 !important;
  }
  [data-kit-theme='dark'] & {
    background: rgba(30, 30, 30, 0.3);

    .header-menu-content {
      background: none;
    }
    a {
      color: #fff !important;
    }
  }
}

.menu-icon {
  height: 12px;
  margin-right: 8px;
  vertical-align: middle;
}

.menu-icon-focus {
  height: 12px;
  margin-right: 8px;
  vertical-align: middle;
  display: none;
}

.ant-menu-item-selected,
.ant-menu-submenu-selected {
  .menu-icon {
    display: none;
  }
  .menu-icon-focus {
    display: inline-block;
  }
}

[data-kit-theme='dark'] .ant-table {
  tbody {
    tr {
      background: $dark-card-bg;
      &:hover {
        background: $dark-card-bg-hover !important;
        td {
          background: $dark-card-bg-hover !important;
        }
      }
      td {
        color: $dark-color;
      }
    }
  }
}

.ant-switch-handle::before {
  top: 2px !important;
  right: 2px !important;
  bottom: 2px !important;
  left: 2px !important;
  background-color: $orange !important;
}

.balance-num {
  color: $orange;
  font-weight: 800;
  margin-left: 2px;
}

.address {
  font-family: monospace;
}

.disappear {
  visibility: hidden;
}

.ant-switch {
  height: unset !important;
  border: 1px solid $orange !important;
  background: transparent !important;
  white-space: nowrap;
  padding: 0 2px !important;
  .ant-switch-inner {
    color: $light-color;
  }
  &.ant-switch-checked {
    background: rgba(255, 198, 50, 0.3) !important;
  }
}

[data-kit-theme='dark'] .ant-switch {
  background: rgba(19, 20, 39, 0.35) !important;
  padding: 2px 4px;
  .ant-switch-inner {
    color: $dark-color;
  }
  &.ant-switch-checked {
    .ant-switch-inner {
      color: $orange !important;
    }
    background: rgba(255, 198, 50, 0.3) !important;
  }
}

.orange {
  color: $orange;
}

.red {
  color: #ff5a5a;
}

.web3modal-modal-lightbox {
  z-index: 999 !important;
}

.reward-balance {
  font-size: 20px;
  color: $orange;
  font-weight: 800;
  margin: 0 4px;
}

a {
  color: $orange;
  &:hover,
  &:active,
  &:focus {
    color: $orange;
  }
}

[data-kit-theme='dark'] a {
  color: $orange;
  &:hover,
  &:active,
  &:focus {
    color: $orange;
  }
}

.ant-form-item-label > label > .anticon {
  color: #595c97 !important;
}

.ant-form-item-label > label {
  color: #969696 !important;
}

[data-kit-theme='dark'] .ant-form-item-label > label > .anticon {
  color: #808098 !important;
}

@media screen and (max-width: 768px) {
  .balance-tag {
    float: right;
  }
}

.ant-select,
.ant-select-selector {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.ant-select-dropdown,
.ant-dropdown-menu,
.ant-dropdown {
  padding: 0 !important;
  color: #434667 !important;
  background: #f3f3f8 !important;
  border: 1px solid #e8e8ef !important;
  .ant-select-item-option-active,
  .ant-dropdown-menu-item {
    color: #434667 !important;
    background: #fff !important;
    &:hover {
      background: #eee !important;
    }
    & > a {
      color: #434667 !important;
    }
  }
}

.section-title {
  font-weight: 800;
  font-size: 24px;
  position: relative;
  .more {
    position: absolute;
    right: 0;
    top: 6px;
    font-weight: 400;
    font-size: 14px;
  }
  &::after {
    content: '';
    display: block;
    margin: 12px auto 52px;
    background: $orange;
    border-radius: 2px;
    width: 64px;
    height: 4px;
  }
  @media screen and (max-width: 576px) {
    .more {
      position: relative;
    }
  }
}

[data-kit-theme='dark'] {
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #5a5a5a;
  }
  .ant-menu-item {
    a {
      color: #fff;
      &:hover {
        color: $orange;
      }
    }
  }
  .analytics-block {
    .item {
      &:nth-child(even) {
        border-left: 1px solid #424560;
        border-right: 1px solid #424560;
      }
      // .num {
      //   color: $dark-color;
      // }
      .title {
        color: $dark-gray-9;
      }
    }
  }
  .ant-form-item-label > label {
    color: #7373d8 !important;
  }
  .card-title {
    color: $dark-color !important;
  }
  .ant-select-dropdown,
  .ant-dropdown-menu,
  .ant-dropdown {
    color: #fff !important;
    background: #232539 !important;
    border: 1px solid #424560 !important;
    .ant-select-item-option-active,
    .ant-dropdown-menu-item {
      color: #fff !important;
      background: #212038 !important;
      &:hover {
        background: #1c1e2c !important;
      }
      & > a {
        color: #fff !important;
      }
    }
  }
}

.switch-theme-icon {
  max-width: unset;
}

.form-two-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-btn {
    margin: 0 12px;
    width: 35%;
  }
}

.ant-input-group-addon {
  padding-left: 0 !important;
}

.card {
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
  border: none;
  .btn-link {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
  .ant-form {
    .ant-input-affix-wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
    .ant-input {
      height: 40px;
    }
    .ant-form-item {
      .ant-col:last-child {
        text-align: right;
      }
    }
  }
}

.claim-all {
  margin-top: 8px;
}

.analytics-block {
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 24px 0;
  .item {
    text-align: center;
    width: 100%;
    &:nth-child(even) {
      border-left: 1px solid #e8e8ef;
      border-right: 1px solid #e8e8ef;
    }
    &:first-child {
      border-left: none !important;
    }
    &:last-child {
      border-right: none !important;
    }
    .num {
      color: $orange;
      font-weight: 800;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 12px;
    }
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: $light-gray-9;
    }
  }
}

@media screen and (max-width: 992px) {
  .analytics-block {
    display: block;
    .item {
      width: 50%;
      display: inline-block;
      border: none !important;
      margin-bottom: 16px;
      .num {
        margin-bottom: 4px;
      }
    }
  }
}

.ant-menu-horizontal {
  .ant-menu-item {
    &::after {
      display: none;
    }
    &:hover,
    &.ant-menu-item-active {
      color: $orange !important;
    }
    &.ant-menu-item-selected {
      color: $orange !important;
      a {
        color: $orange !important;
        &:hover {
          color: $orange !important;
        }
      }
    }
    a {
      &:hover,
      &:active,
      &:focus {
        color: $orange !important;
      }
    }
  }

  .ant-menu-submenu {
    &::after {
      display: none;
    }
    &.ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        color: $orange;
      }
    }
    .ant-menu-submenu-title {
      color: #fff;
    }
    &:hover {
      .ant-menu-submenu-title {
        color: $orange;
      }
    }
  }

  .ant-menu-title-content a::before {
    display: none;
  }
}

.ant-menu-item {
  a {
    color: #b8b6c4;
    &:hover {
      color: $orange;
    }
  }
}

.language-menu {
  .ant-dropdown-menu-item {
    font-size: 16px;
  }
}

.navigation-item {
  padding: 0 !important;
  .ant-menu-title-content {
    display: block;
    a {
      padding: 0 20px;
      display: block;
    }
  }
}

.ant-tag.wrong-network-tag {
  font-size: 15px !important;
  padding: 4px 8px;
  display: flex;
  align-items: center;
}

.text-align-right {
  text-align: right;
}

.web3modal-provider-container {
  &:hover {
    background: rgb(20, 20, 20);
  }
}

@media screen and (max-width: 576px) {
  .balance-tag {
    display: none;
  }
  .logo-wrapper {
    height: 60px;
  }
}

.tabs-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 48px auto 36px;

  .tabs {
    a {
      color: $dark-special-text;
      font-size: 18px;
      border-right: 1px solid #fff;
      padding: 2px 16px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: unset;
      }
      &.active {
        color: $orange;
      }
    }
  }
  .referral-switch {
    height: 24px !important;
    margin-left: 8px;
    vertical-align: middle;
  }
}
@media (max-width: 992px) {
  .tabs-wrapper {
    .tabs {
      a {
        font-size: 16px;
      }
    }
  }
}

[data-kit-theme='default'] {
  .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title {
    color: #b8b6c4;
  }
  .ant-menu-item {
    a {
      color: #b8b6c4;
      &:hover {
        color: $orange;
      }
    }
  }
  .ant-menu-item-selected a {
    color: $orange;
  }
  .tabs-wrapper {
    .tabs {
      a {
        color: #b8b6c4;
        border-right: 1px solid #b8b6c4;
        &:last-child {
          border: none;
        }
        &.active {
          color: $orange;
        }
      }
    }
  }
}
