.balance-modal {
  .ant-modal-body {
    padding: 0;
    font-size: 16px;
  }
  .balance-line {
    padding: 20px 24px;
    margin-bottom: 8px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #b78b49;
    font-weight: 600;
    &:last-child {
      border-bottom: none;
    }
    .icon {
      width: 32px;
    }
  }
}

[data-kit-theme='dark'] {
  .balance-modal {
    .balance-line {
      border-bottom: 1px solid #3c3c3c;
      &:last-child {
        border-bottom: none;
      }
    }
    .ant-modal-header {
      background: #0d0f1c;
      border-color: #3c3c3c;
    }
    .ant-modal-content {
      background: #0d0f1c;
    }
  }
}
