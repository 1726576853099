@import 'components/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/* MENU COMPONENT */

.topLine {
  display: flex;
  // flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
  vertical-align: middle;
  font-size: 16px;
}

.logoLink {
  position: relative;
  // &::after {
  //   content: 'BETA';
  //   display: block;
  //   position: absolute;
  //   top: 2px;
  //   right: -32px;
  //   background: #b78b49;
  //   line-height: 1;
  //   padding: 4px;
  //   color: #fff;
  //   font-size: 12px;
  //   border-radius: 8px;
  // }
}

.menu {
  // border-bottom: 1px solid $border;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f7f7f7;
  font-size: rem(15);
  .logo {
    width: 180px;
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* ANTD MENU CUSTOMIZATION */
  .navigation {
    text-align: center;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    // flex: 1 1 0%;
    // overflow: hidden;
    // padding-bottom: 1px;

    .title {
      font-size: rem(15);
    }

    .ant-menu {
      text-align: right;
    }

    /////////////////////////////////////////////////////////////////////////////////////////
    /* ICON */
    .icon {
      color: $gray-5;
      margin-right: rem(10);
      font-size: rem(17);
      transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      position: relative;
      top: 2px;

      @at-root {
        :global(.ant-menu-submenu-popup) {
          .icon {
            display: none;
          }
        }
      }
    }

    :global(.ant-menu-submenu-title) {
      color: $text;

      &:hover {
        color: $primary;
      }
    }

    :global(.ant-menu-item) {
      &:hover {
        border-bottom: 2px solid $orange !important;
      }
      > a {
        color: #434667;

        &:hover {
          color: $orange !important;

          .icon {
            color: $orange !important;
          }
        }
      }
    }

    :global(.ant-menu-item-selected) {
      font-weight: 800;
      border-bottom: 2px solid $orange !important;
    }

    :global(.ant-menu-horizontal) {
      border-bottom: 0;
      background: transparent;
    }

    :global(.ant-menu-submenu-selected) {
      :global(.ant-menu-submenu-title),
      .icon {
        color: $primary;
      }
    }

    :global(.ant-menu-submenu-active) {
      .icon {
        color: $primary;
      }
    }
  }
}

/////////////////////////////////////////////////////////////////////////////////////////
/* GRAY THEME */
.gray {
  background: $gray-1 !important;
}

/////////////////////////////////////////////////////////////////////////////////////////
/* DARK THEME */
.dark {
  color: $dark-banner-color;
  background: $dark-banner-bg;
  border-bottom: none;
  .menu {
    background: #0f101e;
  }
  .navigation {
    background: #212038;
    .icon {
      color: $dark-gray-2;
    }

    :global(.ant-menu-item-group-title) {
      color: darken($dark-gray-3, 10%) !important;
    }

    :global(.ant-menu-submenu-title) {
      color: $dark-gray-2;

      &:hover {
        color: $primary;
      }
    }

    :global(.ant-menu-item) {
      > a {
        color: $dark-gray-2;

        &:hover {
          color: $primary;

          .icon {
            color: $primary;
          }
        }
      }
    }
  }
}
