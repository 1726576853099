@import 'components/mixins.scss';

.footer {
  margin: 120px auto 0;
  padding: 0 rem(30) rem(30);

  @media (max-width: $sm-max-width) {
    padding: 0 rem(16) !important;
  }
}

.links {
  display: inline-block;
  text-align: left;
  list-style: none;
  font-size: 14px;
  margin: 0;
  margin-right: 32px;
  vertical-align: top;
  padding: 0;
  li {
    color: $orange;
    margin-bottom: 4px;
  }
}

.footerTitle {
  font-size: 20px;
  text-align: left;
  font-weight: 600;
  margin-bottom: 8px;
}

.risk {
  font-size: 12px;
  margin-bottom: 6px;
}

.footerInner {
  // border-top: 1px solid $border;
  padding-top: rem(30);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  color: $black;
  font-size: rem(17);
  text-transform: uppercase;
  letter-spacing: -1px;
  font-weight: 900;

  span {
    display: inline-block;
    height: rem(13);
    width: rem(13);
    background: $black;
    border-radius: 20px;
    margin-left: rem(8);
    vertical-align: middle;
    position: relative;
    top: -2px;
  }
}
// .socials {
//   margin: 16px auto;
// }
.socialLink {
  margin: 0 12px;
  display: inline-block;
  transition: all 0.4s;
  height: 32px;
  width: 32px;
  &:hover {
    // filter: brightness(100%);
    filter: sepia(69%) saturate(708%) brightness(100%);
  }
}

.socialIcons {
  width: 32px;

  height: 32px;
}

.copyright {
  font-size: 14px;
  color: #c9c9c9;
  margin-top: 12px;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .socials {
    margin: 64px auto 32px;
  }
  .socialLink {
    margin: 0 16px;
  }
  .socialIcons {
    height: 24px;
  }
}

@media screen and (max-width: 992px) {
  .linksWrapper {
    text-align: left;
  }
  .links {
    margin-right: 0;
    margin-left: 0;
  }
}

// dark theme
[data-kit-theme='dark'] {
  .footer {
    color: $dark-gray-1;
  }

  .footerInner {
    border-top: 1px solid $dark-gray-4;
  }

  // .socialLink{
  //   &:hover {
  //     filter: brightness(0%);
  //   }
  // }

  .logo {
    span {
      background: $dark-gray-1;
    }
  }
}
