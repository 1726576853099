@import 'components/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/* CARDS */

.card,
.card-header,
.card-footer {
  border-color: $border;
  border-radius: calc(7px - 1px);
}

.card.with-more {
  padding-bottom: 52px !important;
}

.card-header,
.card-footer,
.card-body {
  background: transparent;
  padding: rem(20) rem(25);
  @media (max-width: $md-max-width) {
    padding-left: rem(15);
    padding-right: rem(15);
  }

  &:last-child {
    border-radius: 0 0 calc(7px - 1px) calc(7px - 1px);
  }

  &:first-child {
    border-radius: calc(7px - 1px) calc(7px - 1px) 0 0;
  }
}

.card {
  margin-bottom: rem(30);
  background: $light-card-bg;
  border-color: $light-card-border;
  border-radius: 8px;
  .ant-btn-submit {
    width: 50%;
    margin: 0 auto;
    height: 32px;
  }
  &-borderless {
    border-color: transparent !important;
  }

  &-solid {
    color: $white;
    border-color: transparent !important;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white !important;
    }
    .card-header,
    .card-footer {
      border-color: rgba($white, 0.3);
    }
  }
}

.card-header {
  &:last-child {
    border-bottom: none !important;
  }

  &-flex {
    display: flex;
    flex-direction: row;
    padding-top: 0;
    padding-bottom: 0;
    min-height: rem(62);
  }
  &-borderless {
    border-color: transparent !important;
    + .card-body {
      padding-top: 0;
    }
  }
}

.ant-alert {
  border-radius: 8px !important;
  background: $light-card-bg !important;
  border: none !important;
  font-size: 20px !important;
  box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 20px !important;

  // border-color: $light-card-border !important;
}

// dark theme
[data-kit-theme='dark'] {
  .ant-alert {
    background: $dark-card-bg !important;
    border: none;
    // border-color: $dark-card-border !important;
  }
  .card {
    background: $dark-card-bg;
    border-color: $dark-card-border;

    &-solid {
      .card-header,
      .card-footer {
        border-color: rgba($gray-2, 0.4);
      }
    }
  }
  .card-header,
  .card-footer {
    border-color: $dark-gray-4;
  }
}
